<script setup lang="ts">
  import { useMpScreen } from '@borg/ui';

  const MOBILE_BANNER_ID = 'a69c75d7';
  const { breakpoints } = useMpScreen();
  const cacheBuster = ref();

  onMounted(() => {
    cacheBuster.value = new Date().getTime();
  });
</script>

<template>
  <div class="banner-container">
    <iframe
      v-if="breakpoints.isLg"
      id="a7a04939"
      name="a7a04939"
      :src="`https://ads.vrabotuvanje.com.mk/www/delivery/afr.php?zoneid=6&amp;cb=${cacheBuster}`"
      frameborder="0"
      scrolling="no"
      width="970"
      height="250"
      allow="autoplay"
    />
    <!-- <iframe
      v-else
      :id="MOBILE_BANNER_ID"
      name="a69c75d7"
      :src="`https://ads.vrabotuvanje.com.mk/www/delivery/afr.php?zoneid=22&amp;cb=${cacheBuster}`"
      frameborder="0"
      scrolling="no"
      width="300"
      height="250"
      allow="autoplay"
    /> -->
  </div>
</template>

<style scoped lang="scss">
  .banner-container {
    iframe {
      margin-top: var(--mp-space-90);
    }
  }
</style>
