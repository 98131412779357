<script setup lang="ts">
  import { MpButton } from '@borg/ui';

  function openEmployersPage() {
    const { $isProd } = useNuxtApp();
    const employersSite = $isProd
      ? 'https://rabotodavaci.vrabotuvanje.com.mk/'
      : 'http://www.vrabotuvanje.test-vrabotuvanje-dns-emp-bfm2.dev-vrabotuvanje/';
    navigateTo(employersSite, { open: { target: '_blank' } });
  }
</script>

<template>
  <ul
    data-test="header-links-container"
    class="header-links"
  >
    <li>
      <LinksLocale
        to="jobs"
        data-test="HEADER_WIDGETS.SEARCH_JOBS"
      >
        {{ $t('HEADER_WIDGETS.SEARCH_JOBS') }}
      </LinksLocale>
    </li>
    <li>
      <LinksLocale
        to="employers"
        data-test="HEADER_WIDGETS.EMPLOYER_PROFILES"
      >
        {{ $t('HEADER_WIDGETS.EMPLOYER_PROFILES') }}
      </LinksLocale>
    </li>
    <li>
      <LinksLocale
        to="calculator-salary"
        data-test="HEADER_WIDGETS.MONEY_TOOLS"
      >
        {{ $t('HEADER_WIDGETS.MONEY_TOOLS') }}
      </LinksLocale>
    </li>
    <li>
      <LinksLocale
        to="advices"
        data-test="HEADER_WIDGETS.ADVICES"
      >
        {{ $t('HEADER_WIDGETS.ADVICES') }}
      </LinksLocale>
    </li>
    <li class="header-links__divider" />
    <li>
      <MpButton
        :text="$t('HEADER_WIDGETS.FOR_EMPLOYERS')"
        :semibold-text="false"
        size="sm"
        shape="oval"
        color-scheme="primary"
        class="header-links__external"
        data-test="HEADER_WIDGETS.FOR_EMPLOYERS"
        right-icon="new_window"
        icon-size="xs"
        @click="openEmployersPage"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
  .header-links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--mp-space-60);

    &__external {
      --button-color: var(--site-search-bar-button-color) !important;
      --button-color-dark: var(--site-search-bar-button-hover-color);
      display: flex;
      align-items: center;
      gap: var(--mp-space-30);
    }

    &__external-icon {
      color: var(--mp-color-secondary-default);

      @include dark-mode {
        color: var(--mp-color-secondary-light);
      }
    }

    @include screen-lg {
      margin: 0;
      flex-direction: row;
      align-items: center;
      gap: var(--mp-space-70);
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: var(--mp-color-grey-200);

      @include dark-mode {
        background-color: var(--mp-color-grey-800);
      }

      @include screen-lg {
        flex-direction: row;
        width: 1px;
        height: var(--mp-space-60);
      }
    }
  }
</style>
