<script setup lang="ts">
  import { MpBox } from '@borg/ui';
  import LeftSidebar from './LeftSidebar.vue';

  const projectConfig = useProjectConfig();
  const userStore = useUserStore();
  const isTopHeaderFullWidth = computed(() => projectConfig.parameters.fullWidthHeader);
</script>

<template>
  <MpBox class="header no-print">
    <div class="header__top">
      <div class="header__top-left">
        <div class="header__left-sidebar-container">
          <LeftSidebar />
        </div>
        <LinksLocale
          class="header__title"
          :to="{ name: 'index' }"
        >
          <Logo
            max-width="245px"
            height="35"
          />
        </LinksLocale>
      </div>
      <div
        :class="{
          'header__links': true,
          'header__links--centered': isTopHeaderFullWidth,
        }"
      >
        <HeaderLinks />
      </div>
      <div class="header__top-right">
        <UserAdminButton />
        <div class="header__language-switch-wrapper">
          <LanguageSwitch :full-name="false" />
        </div>
        <RightSidebar v-if="userStore.isAuthenticated" />
        <RightUserIndicator v-else />
      </div>
    </div>
  </MpBox>
</template>

<style scoped lang="scss">
  .header {
    position: relative;

    &__left-sidebar-container {
      margin-bottom: var(--mp-space-20);

      @include screen-lg {
        display: none;
      }
    }

    &__top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: var(--mp-space-70);
      padding: var(--site-header-vertical-padding) 0;

      @include screen-md {
        flex-wrap: nowrap;
      }
    }

    &__top-left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: var(--mp-space-50);

      @include screen-lg {
        gap: var(--mp-space-70);
      }
    }

    &__top-right {
      display: flex;
      align-items: center;
      gap: var(--mp-space-50);
    }

    &__language-switch-wrapper {
      display: none;

      @include screen-lg {
        display: block;
      }
    }

    &__links {
      display: none;
      flex: 2;

      @include screen-lg {
        display: inherit;
      }

      &--centered {
        flex: none;
      }
    }
  }
</style>
